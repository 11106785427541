import useSWR from "swr";
import {
  jwtRefresherToken,
  useUserJwt,
  useUserSession,
} from "../context/SessionProvider";
import { useState, useCallback, useMemo } from "react";
import { API_BASE_URL } from "../utils";

const readUser = async ({ user_id, jwt }) => {
  if (!jwt || !user_id) {
    return;
  }
  jwt = await jwtRefresherToken(jwt);

  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + jwt);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const res = await fetch(
      `${API_BASE_URL}/customer/${user_id}`,
      requestOptions
    );
    const resJson = await res.json();
    if (Array.isArray(resJson.data) && resJson.data.length > 0) {
      return resJson.data[0];
    } else {
      throw new Error("The user was not found.");
    }
  } catch (error) {
    console.error("Error caught: ", error);
    throw new Error("The user was not found.");
  }
};

const followUser = async ({ user_id, jwt, logged_in_id }) => {
  if (logged_in_id === user_id) {
    throw new Error("You can't follow yourself.");
  }
  if (!jwt || !user_id) {
    return;
  }
  jwt = await jwtRefresherToken(jwt);

  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + jwt);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const res = await fetch(
      `${API_BASE_URL}/customerFollow/${user_id}`,
      requestOptions
    );
    const resJson = await res.json();
    if (resJson.status === "ok") {
      return resJson.data;
    } else {
      throw new Error("The user was not found. Status not ok");
    }
  } catch (error) {
    console.error("Error caught: ", error);
    throw new Error("The user was not found.");
  }
};

const unfollowUser = async ({ user_id, jwt, logged_in_id }) => {
  if (logged_in_id === user_id) {
    throw new Error("You can't unfollow yourself.");
  }
  if (!jwt || !user_id) {
    return;
  }
  jwt = await jwtRefresherToken(jwt);

  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + jwt);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const res = await fetch(
      `${API_BASE_URL}/customerUnfollow/${user_id}`,
      requestOptions
    );
    const resJson = await res.json();
    if (resJson.status === "ok") {
      return resJson.data;
    }
  } catch (error) {
    console.error("Error caught: ", error);
    throw new Error("The user was not found.");
  }
};

const readFollowUser = async ({ user_id, jwt, logged_in_id }) => {
  if (logged_in_id === user_id) {
    return;
  }
  if (!jwt || !user_id) {
    return;
  }
  jwt = await jwtRefresherToken(jwt);

  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + jwt);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const res = await fetch(
      `${API_BASE_URL}/readFollowCustomer/${user_id}`,
      requestOptions
    );
    const resJson = await res.json();
    if (resJson.data) {
      return resJson.data.isFollowing;
    } else {
      throw new Error("The user was not found.");
    }
  } catch (error) {
    console.error("Error caught: ", error);
    throw new Error("The user was not found.");
  }
};

const useUser = ({ user_id }) => {
  const [likeDisabled, setLikeDisabledUi] = useState(false);
  const jwt = useUserJwt();
  const session = useUserSession();
  const logged_in_id = session?.userSub;

  const { data, isLoading, error, mutate } = useSWR(
    { url: `user`, user_id, logged_in_id },
    () => readUser({ jwt, user_id })
  );

  const readFollowUserRes = useSWR(
    { url: `userFollow`, user_id, logged_in_id },
    () => readFollowUser({ jwt, user_id, logged_in_id })
  );

  const handleFollowUser = useCallback(async () => {
    setLikeDisabledUi(true);
    session.addAllTable(data);
    await followUser({ user_id, jwt, logged_in_id });
    readFollowUserRes.mutate(true);
    mutate({ ...data, follower_count: data.follower_count + 1 });
    setTimeout(() => setLikeDisabledUi(false), 5000);
  }, [data, user_id, jwt, logged_in_id, session, readFollowUserRes, mutate]);

  const handleUnfollowUser = useCallback(async () => {
    setLikeDisabledUi(true);
    session.deleteAllTable(data.customer_id, "customer_id");
    await unfollowUser({ user_id, jwt, logged_in_id });
    readFollowUserRes.mutate(false);
    mutate({ ...data, follower_count: data.follower_count - 1 });
    setTimeout(() => setLikeDisabledUi(false), 5000);
  }, [data, user_id, jwt, logged_in_id, session, readFollowUserRes, mutate]);

  const isTheLoggedInUser = useMemo(
    () => logged_in_id && user_id && logged_in_id === user_id,
    [logged_in_id, user_id]
  );
  const isFollowing = useMemo(
    () => readFollowUserRes.data,
    [readFollowUserRes.data]
  );

  return {
    user: data,
    isLoading,
    error,
    mutate,
    likeDisabled,
    setLikeDisabledUi,
    handleFollowUser,
    handleUnfollowUser,
    isTheLoggedInUser,
    isFollowing,
    readFollowResLoading: readFollowUserRes.isLoading,
    readFollowError: readFollowUserRes.error,
    readFollowMutate: readFollowUserRes.mutate,
  };
};

export default useUser;
