import { API_BASE_URL } from ".";
import { jwtRefresherToken } from "../context/SessionProvider";

export const readMySubscriptions = async ({ jwt }) => {
  if (!jwt) {
    return;
  }
  jwt = await jwtRefresherToken(jwt);

  console.log("readMySubscriptions passed jwt");

  const myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + jwt);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const res = await fetch(API_BASE_URL + "/pay", requestOptions);
    console.log("res subs");
    if (!res.ok) {
      throw new Error("read was not successfull");
    }

    const { data } = await res.json();
    console.log("data readMySubscriptions 2", data);

    return data;
  } catch (error) {
    console.log("the error is in fetch: " + error);
  }
};
